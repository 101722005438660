<template>
  <div>
    <ClientOnly>
      <template v-if="componentName">
        <Component :is="resolvedComponent" v-bind="data"></Component>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { resolveComponent as vResolveComponent, computed } from "vue";

const props = defineProps({
  strapiComponentName: { type: String, required: true },
  componentName: { type: String, required: true },
  data: { type: Object, required: true },
});

const resolvedComponent = computed(() =>
  vResolveComponent(props.componentName.toString())
);
</script>
